/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, useTheme } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  Card as CardDefault,
  Col,
  Container,
  Illustration,
  TitleHighlight,
  Typography,
  Wrapper as WrapperDefault,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Section = styled('section')(({ theme }) => ({
  margin: theme.spacing(5, 0, 20.5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    margin: theme.spacing(28, 0, 25),
  },
}));

const HeroTitle = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'inline-block',
    [theme.breakpoints.down(Breakpoints.Md)]: {
      display: 'block !important',
      margin: 'auto',
      width: 'max-content',
    },
  },

  lineHeight: theme.typography.pxToRem(16),
  marginBottom: theme.spacing(20),
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: theme.spacing(200),
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Xs)]: {
    '& span': {
      fontSize: theme.typography.pxToRem(39),
      transform: `translateY(${theme.typography.pxToRem(5)})`,
    },
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& span': {
      fontSize: theme.typography.pxToRem(88),
    },
    ...theme.typography.h1,
    marginBottom: theme.spacing(7),
  },
}));

const Wrapper = styled(WrapperDefault)({
  display: 'flex',
  flexDirection: 'column',
});

const Card = styled(CardDefault)(({ theme }) => ({
  backgroundSize: theme.typography.pxToRem(335),
  margin: theme.spacing(0, 0, 20.5),
  padding: theme.spacing(6),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    backgroundSize: '46vw',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(48, 0, 52.5),
    padding: theme.spacing(10),
  },
}));

const CardContent = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: '45%',
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(20),
  },
}));

const CardAuthor = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 500,
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  opacity: 0.6,
}));

const CardBigQuote = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  textAlign: 'center',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    margin: '0 auto',
    maxWidth: theme.typography.pxToRem(826),
    textAlign: 'left',
  },
}));

const CustomIllustration = styled(Illustration)(({ theme }) => ({
  '& .asset': {
    bottom: 0,
    left: 0,
    width: '110%',
  },
  '& .gatsby-image-wrapper': {
    height: '450px',
  },
  height: '500px',
  marginBottom: '5%',
  marginLeft: '5%',
  marginRight: '15%',
  marginTop: '-10%',
  width: '90%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '50%',
  },
}));

const Hero: React.FC<HeroProps> = ({ content }) => {
  const theme = useTheme();

  const card =
    content.testimonials[
      Math.floor(Math.random() * content.testimonials.length)
    ];
  return (
    <Section>
      <Container>
        <HeroTitle as="h1" variant="h4">
          {content.title}
        </HeroTitle>
      </Container>

      <Container>
        <Col xs={12}>
          <Wrapper>
            <Card bgColor={theme.palette.error.main}>
              <CustomIllustration
                alt=""
                objectPosition="center"
                image={card.image.childImageSharp.gatsbyImageData}
                asset={card.imageAsset?.childImageSharp.original.src}
                assetAspectRatio={
                  card.imageAsset
                    ? card.imageAsset?.childImageSharp.original.width /
                      card.imageAsset?.childImageSharp.original.height
                    : undefined
                }
              />
              <CardContent>
                <CardTitle variant="body1">{card.quote}</CardTitle>
                <CardAuthor variant="body1">{card.author}</CardAuthor>
                <CardDescription variant="body1">
                  {card.description}
                </CardDescription>
              </CardContent>
            </Card>

            <CardBigQuote variant="h4">{content.bigQuote}</CardBigQuote>
          </Wrapper>
        </Col>
      </Container>
    </Section>
  );
};

type Testimony = {
  author: string;
  quote: string;
  image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  imageAsset?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
      original: IImage;
    };
  };
  description: string;
};

export interface HeroProps {
  content: {
    title: string;
    bigQuote: string;
    testimonials: Testimony[];
  };
}

const query = graphql`
  fragment HeroCommunity on CommunityYaml {
    hero {
      title
      bigQuote
      testimonials {
        author
        quote
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        imageAsset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
        description
      }
    }
  }
`;

export { Hero, query };
