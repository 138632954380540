/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';

import {
  Carousel,
  CarouselRenderDotProps,
  Col,
  Container,
  TitleHighlight,
  Typography,
} from 'components/UI';
import { SmallTexture } from 'components/UI/Icons/SmallTexture';

interface SliderItemProps {
  slider: {
    title: string;
    list: [
      {
        text: string;
        logo: {
          alt: string;
          childImageSharp: { gatsbyImageData: IGatsbyImageData };
        };
      }
    ];
  };
}

const PressSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(10),

  [theme.breakpoints.up(1080)]: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(40),
  },
}));

const SliderItem = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  overflow: 'hidden',

  [theme.breakpoints.up(1080)]: {
    paddingRight: theme.spacing(32.5),
  },
}));

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'block',
  },
  fontWeight: 500,

  [theme.breakpoints.up(1080)]: {
    ...theme.typography.h2,
    fontWeight: 500,
  },
}));

const SliderText = styled(Typography)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(1080)]: {
    ...theme.typography.h3,
    fontWeight: 400,
  },
}));

const SliderDots = styled(({ isActive: _, ...p }) => <div {...p} />)<
  Theme,
  { isActive: boolean }
>(({ theme, isActive }) => ({
  cursor: 'pointer',
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(3),
  maxWidth: theme.typography.pxToRem(144),
  minWidth: theme.typography.pxToRem(160),
  opacity: isActive ? '1' : '0.5',
  position: 'relative',
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',

  [theme.breakpoints.up(1080)]: {
    '& :last-child': {
      marginRight: '0%',
    },
    marginRight: theme.spacing(12),
    marginTop: theme.spacing(19.25),
    maxWidth: '100%',
    minWidth: theme.typography.pxToRem(100),
    width: theme.typography.pxToRem(144),
  },
}));

const SliderDotsBaseContainer = styled('nav')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  overflow: 'auto',
  paddingBottom: theme.spacing(5),
  position: 'relative',

  [theme.breakpoints.up(1080)]: {
    overflow: 'visible',
    paddingBottom: 0,
  },
}));

const SliderOverflow = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  width: '100%',
});

const SliderDotsContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <SliderOverflow>
      <SliderDotsBaseContainer>{children}</SliderDotsBaseContainer>
    </SliderOverflow>
  );
};

const DotTexture = styled(({ isActive: _, ...p }) => <SmallTexture {...p} />)<
  Theme,
  { isActive: boolean }
>(({ theme, isActive }) => ({
  clipPath: isActive ? 'inset(0px 0% 0px 0px)' : 'inset(0px 100% 0px 0px)',
  maxWidth: '100%',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
  }),
}));

export const query = graphql`
  query PressPageQuery {
    pressYaml {
      slider {
        title
        list {
          text
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const PressSlider: React.FC = () => {
  const content = useStaticQuery<{ pressYaml: SliderItemProps }>(query);
  const slider = content.pressYaml.slider;
  const [autoplay, setAutoplay] = useState(true);

  const renderDot = ({ index, isActive, goTo }: CarouselRenderDotProps) => {
    const renderDotIndex = slider.list[index];
    const handleClick = () => {
      // stop autoplay if user clicks an item
      setAutoplay(false);
      goTo(index);
    };
    return (
      <SliderDots key={index} isActive={isActive} onClick={handleClick}>
        <GatsbyImage
          key={renderDotIndex.logo.alt}
          alt={renderDotIndex.logo.alt}
          image={renderDotIndex.logo.childImageSharp.gatsbyImageData}
        />
        <DotTexture isActive={isActive} />
      </SliderDots>
    );
  };

  return (
    <PressSection>
      <Container>
        <Col xs={12} md={4}>
          <Title as="h2" variant="h4">
            {slider.title}
          </Title>
        </Col>
        <Col xs={12} md={8}>
          <Carousel
            dots
            autoPlay={autoplay}
            speed={6000}
            mobileFullWidth
            renderDot={renderDot}
            renderDotsContainer={SliderDotsContainer}
          >
            {slider.list.map((sliderItem) => {
              return (
                <SliderItem key={sliderItem.text}>
                  <SliderText variant="body1">{sliderItem.text}</SliderText>
                </SliderItem>
              );
            })}
          </Carousel>
        </Col>
      </Container>
    </PressSection>
  );
};

export { PressSlider };
