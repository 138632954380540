/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import { Hero, HeroProps } from 'components/contents/Community/Hero';
import { Reviews } from 'components/contents/Community/Reviews';
import Layout from 'components/partials/Layout';
import { LongList, LongListProps } from 'components/partials/LongList';
import { PressSlider } from 'components/partials/PressSlider';

// Types

interface Props {
  data: {
    communityYaml: {
      metadata: MetaData;
      hero: HeroProps['content'];
      longList: LongListProps['content'];
    };
  };
}

const Community: React.FC<Props> = ({ data }) => {
  return (
    <Layout
      title={data.communityYaml.metadata.title}
      description={data.communityYaml.metadata.description}
    >
      <Hero content={data.communityYaml.hero} />
      <Reviews />
      <LongList content={data.communityYaml.longList} />
      <PressSlider />
    </Layout>
  );
};

export const query = graphql`
  query CommunityQuery {
    communityYaml {
      metadata {
        title
        description
      }
      ...HeroCommunity
      longList {
        title
        image {
          childImageSharp {
            gatsbyImageData
            original {
              height
              width
              src
            }
          }
        }
        imageAsset {
          childImageSharp {
            gatsbyImageData
            original {
              height
              width
              src
            }
          }
        }
        list {
          title
          text
          image {
            childImageSharp {
              gatsbyImageData
              original {
                height
                width
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default Community;
