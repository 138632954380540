/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Typography, Wrapper } from 'components/UI';
import { Breakpoints } from 'utils/enums';

interface NewsletterProps {
  isNewsletter?: boolean;
}

interface Props extends NewsletterProps {
  image: IGatsbyImageData;
  title: string;
  text: string;
  linkInstance?: React.ReactNode;
}

const ImageWrap = styled('div')(({ theme }) => ({
  '& > *': {
    margin: 'auto 0',
  },
  display: 'flex',
  height: theme.typography.pxToRem(70),
  marginBottom: theme.spacing(4),
}));

const Image = styled(GatsbyImage)(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(4.5),
  maxWidth: theme.typography.pxToRem(50),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(2.5),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: theme.typography.pxToRem(24),
  marginBottom: theme.spacing(2.5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(4),
  },
}));

const Text = styled(({ isNewsletter: _, ...p }) => <Typography {...p} />)<
  Theme,
  NewsletterProps
>(({ isNewsletter }) => ({
  maxWidth: isNewsletter ? '65%' : '100%',
}));

const Feature: React.FC<Props> = ({
  image,
  title,
  text,
  linkInstance,
  isNewsletter,
}) => {
  return (
    <Wrapper>
      <ImageWrap>
        <Image alt="" className="feature--image-wrap" image={image} />
      </ImageWrap>
      <Title>{title}</Title>
      <Text isNewsletter={isNewsletter}>{text}</Text>
      {linkInstance}
    </Wrapper>
  );
};

export { Feature };
