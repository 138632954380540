/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Feature } from 'components/partials/Feature';
import {
  Col,
  Container,
  Illustration,
  TitleHighlight,
  Wrapper,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

type ImageProp = {
  childImageSharp: { gatsbyImageData: IGatsbyImageData; original: IImage };
};

interface LongListArrayProps {
  image: ImageProp;
  text: string;
  title: string;
}

export interface LongListProps {
  content: {
    title: string;
    image?: ImageProp;
    imageAsset?: ImageProp;
    list: LongListArrayProps[];
  };
}

const LongListSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  marginTop: theme.spacing(20),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(25),
    marginTop: theme.spacing(37.5),
  },
}));

const TitleWrapper = styled(Wrapper)(({ theme }) => ({
  '& .illustration': {
    '& .asset': {
      left: '-50%',
      top: '22%',
      width: '200%',
    },

    display: 'none',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      display: 'block',
      margin: '50% auto',
      width: '60%',
    },
  },
  marginBottom: theme.spacing(10),
  maxWidth: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(410),
  },
}));

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& > span': {
    display: 'block',
    width: 'max-content',
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
  },
}));

const FeatureWrapper = styled(Wrapper)(({ theme }) => ({
  marginBottom: theme.spacing(9.25),
  maxWidth: '100%',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(15),
    maxWidth: '40%',
  },
}));

const ListWrapper = styled(Wrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
  },
}));

const LongList: React.FC<LongListProps> = ({ content }) => {
  return (
    <LongListSection>
      <Container>
        <Col md={5}>
          <TitleWrapper>
            <Title as="h2" variant="h4">
              {content.title}
            </Title>

            {content.image?.childImageSharp && (
              <Illustration
                alt=""
                className="illustration"
                image={content.image.childImageSharp.gatsbyImageData}
                asset={content.imageAsset?.childImageSharp.original.src}
                assetAspectRatio={
                  content.imageAsset
                    ? content.imageAsset?.childImageSharp.original.width /
                      content.imageAsset?.childImageSharp.original.height
                    : undefined
                }
              />
            )}
          </TitleWrapper>
        </Col>
        <Col md={7}>
          <ListWrapper>
            {content.list.map((listItem, index) => {
              return (
                <FeatureWrapper key={`${listItem.title}-${index}`}>
                  <Feature
                    image={listItem.image.childImageSharp.gatsbyImageData}
                    title={listItem.title}
                    text={listItem.text}
                  />
                </FeatureWrapper>
              );
            })}
          </ListWrapper>
        </Col>
      </Container>
    </LongListSection>
  );
};

export { LongList };
